import React, { PropsWithChildren, useEffect, useState } from 'react'
import Markdown from 'react-markdown'

import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

type MarkdownFileProps = {
  source: Parameters<typeof fetch>[0]
}

type MarkdownFileState = {
  content?: string
  loading: boolean
}

export const MarkdownFile: React.FC<MarkdownFileProps> = (props) => {
  const [state, setState] = useState<MarkdownFileState>({
    loading: false,
  })

  useEffect(() => {
    const asyncEffect = async () => {
      setState((state) => ({ ...state, loading: true }))
      const response = await fetch(props.source)
      const content = await response.text()
      setState((state) => ({ ...state, loading: false, content }))
    }

    asyncEffect()
  }, [props.source])

  const components = {
    p: (props: PropsWithChildren<{}>) => (
      <Typography variant="body1" style={{ marginTop: '20px', width: '100%' }}>
        {props.children}
      </Typography>
    ),
    h1: (props: PropsWithChildren<{ level: number }>) => {
      if (props.level === 2) {
        return (
          <Typography component="h2" variant="h5" style={{ marginTop: '40px', width: '100%' }}>
            {props.children}
          </Typography>
        )
      } else if (props.level === 3) {
        return (
          <Typography component="h3" variant="h6" style={{ marginTop: '30px', width: '100%' }}>
            {props.children}
          </Typography>
        )
      } else {
        return <div style={{ marginTop: 20 }}></div>
      }
    },
    a: (props: PropsWithChildren<{ href?: string }>) => <Link href={props.href}>{props.children}</Link>,
    ul: (props: PropsWithChildren<{ ordered: boolean }>) => {
      if (props.ordered) {
        return <ol style={{ width: '100%' }}>{props.children}</ol>
      } else {
        return <ul style={{ width: '100%' }}>{props.children}</ul>
      }
    },
    li: (props: PropsWithChildren<{}>) => (
      <li>
        <Typography variant="body1">{props.children}</Typography>
      </li>
    ),
  }

  if (!state.content) {
    return null
  }

  return <Markdown components={components}>{state.content}</Markdown>
}
