import { createContext, useContext, useEffect, useRef } from 'react'

export const createScrollNavigation = <ScrollPointIds extends Record<string, string>>(
  scrollPointIds: ScrollPointIds
) => {
  const scrollRefs: {
    [ScrollPointId in keyof ScrollPointIds]?: React.MutableRefObject<HTMLDivElement | null>
  } = {}

  const navigate = (scrollPointId: Extract<keyof ScrollPointIds, string>) => {
    window.history.replaceState({}, document.title, scrollPointIds[scrollPointId] || '.')

    scrollRefs[scrollPointId]?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const navigation = { navigate, scrollToTop }

  const navigationContext = createContext(navigation)

  return {
    ScrollNavigationProvider: ({ children }: React.PropsWithChildren) => {
      useEffect(() => {
        const scrollPointId = (Object.keys(scrollRefs) as Extract<keyof ScrollPointIds, string>[]).find(
          (scrollPointId) => scrollPointIds[scrollPointId] === window.location.hash
        )
        if (scrollPointId) {
          setTimeout(() => navigate(scrollPointId), 50)
        }
      }, [])

      return <navigationContext.Provider value={navigation}>{children}</navigationContext.Provider>
    },
    useScrollNavigation: () => useContext(navigationContext),
    useScrollNavigationPoint: (id: keyof ScrollPointIds) => {
      scrollRefs[id] = useRef<HTMLDivElement | null>(null)
      return scrollRefs[id]
    },
  }
}
