import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

import illustration1 from '../../assets/illustration3-1024.png'
import illustration2 from '../../assets/illustration7-1024.png'
import illustration3 from '../../assets/illustration9-1024.png'
import { Card } from '../../components/Card'
import { useScrollNavigationPoint } from '../../services/scroll-navigation/scrollNavigation'

export const SolutionsCard = () => {
  const ref = useScrollNavigationPoint('solutions')

  return (
    <Card ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <img src={illustration1} alt="Community Hub" style={{ width: '100%' }} />
          <Typography variant="h6" textAlign="center">
            Community Hub
          </Typography>
          <Typography variant="body2" marginTop={2} textAlign="justify">
            Our digital solution acts as a central hub, allowing organizations to effortlessly connect with their
            communities. Through our web and mobile applications, users can stay informed about local events, news, and
            activities, fostering a sense of belonging and engagement within neighborhoods.
          </Typography>
        </Grid>
        <Grid xs={12} sm={4}>
          <img src={illustration2} alt="Seamless Sharing" style={{ width: '100%' }} />
          <Typography variant="h6" textAlign="center">
            Seamless Sharing
          </Typography>
          <Typography variant="body2" marginTop={2} textAlign="justify">
            Our innovative tools enable organizations to easily share information and updates with their communities.
            From important announcements to exciting milestones, our web and mobile applications provide a user-friendly
            platform for seamless sharing, encouraging collaboration and communication among individuals, businesses,
            and institutions.
          </Typography>
        </Grid>
        <Grid xs={12} sm={4}>
          <img src={illustration3} alt="Global Connectivity" style={{ width: '100%' }} />
          <Typography variant="h6" textAlign="center">
            Global Connectivity
          </Typography>
          <Typography variant="body2" marginTop={2} textAlign="justify">
            Breaking down geographical barriers, our digital tools empower organizations to transcend local boundaries
            and connect on a global scale. With our web and mobile applications, users can explore happenings not only
            within their neighborhood and wider community but also across the world, fostering a sense of global
            awareness and expanding horizons.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}
