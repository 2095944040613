import { FloatingMenu } from '../../components/FloatingMenu'
import { Footer } from '../../components/Footer'
import { SpotyLabsMeta } from '../../components/SpotyLabsMeta'
import { ScrollNavigationProvider } from '../../services/scroll-navigation/scrollNavigation'

import { AboutCard } from './AboutCard'
import { ContactCard } from './ContactCard'
import { HomeCard } from './HomeCard'
import { SolutionsCard } from './SolutionsCard'

export const HomeScreen: React.FC = () => {
  return (
    <>
      <SpotyLabsMeta title="Connecting Communities, Empowering Organizations" />
      <FloatingMenu position="fixed" />
      <ScrollNavigationProvider>
        <HomeCard />
        <AboutCard />
        <SolutionsCard />
        <ContactCard />
        <Footer />
      </ScrollNavigationProvider>
    </>
  )
}
