import { Link as RouterLink } from 'react-router-dom'

import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

export const Footer: React.FC = () => {
  return (
    <Container>
      <Divider />
      <Grid
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        paddingTop={2}
        paddingBottom={2}
      >
        <Typography variant="body2" textAlign="center">
          {new Date().getFullYear()} © Spoty Labs | Warschauer Straße 66, 10243 Berlin, Germany
        </Typography>
        <Typography variant="body2" textAlign="center">
          <Link component={RouterLink} to="/privacy">
            privacy policy
          </Link>
          {' | '}
          <Link component={RouterLink} to="/terms">
            terms of service
          </Link>
        </Typography>
      </Grid>
    </Container>
  )
}
