import { createBrowserRouter } from 'react-router-dom'

import { HomeScreen } from '../../features/home-screen/HomeScreen'
import { PrivacyScreen } from '../../features/privacy-screen/PrivacyScreen'
import { TermsScreen } from '../../features/terms-screen/TermsScreen'

export const router = createBrowserRouter([
  { path: '/', element: <HomeScreen /> },
  { path: '/privacy', element: <PrivacyScreen /> },
  { path: '/terms', element: <TermsScreen /> },
])
