import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

import illustration from '../../assets/illustration4-1024.png'
import { Card } from '../../components/Card'
import { useScrollNavigationPoint } from '../../services/scroll-navigation/scrollNavigation'

export const AboutCard = () => {
  const ref = useScrollNavigationPoint('about')

  return (
    <Card ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={4}>
        <Grid xs={12} sm={6} display="flex" alignItems="center">
          <img
            src={illustration}
            alt="A future where organizations seamlessly connect with their communities, unlocking the potential of
          web and mobile applications"
            width="100%"
          />
        </Grid>
        <Grid xs={12} sm={6} display="flex" alignItems="center">
          <Typography variant="body1">
            At{' '}
            <span style={{ fontWeight: 700, letterSpacing: 0, fontFamily: 'Mukta', color: '#2a2a2c' }}>Spoty Labs</span>{' '}
            we envision a future where organizations seamlessly connect with their communities, unlocking the potential
            of web and mobile applications.
            <br />
            <br />
            Our focus is on delivering innovative digital tools that enable effortless tracking, sharing, and discovery
            of local happenings, fostering engagement within neighborhoods and expanding into wider communities. By
            transcending geographical boundaries, we empower organizations to stay connected and informed on a global
            scale.
            <br />
            <br />
            We strive to revolutionize the way information flows, bridging the gaps between individuals, businesses, and
            institutions, ultimately creating a more connected and vibrant world.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}
