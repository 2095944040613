import { useState } from 'react'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

import illustration from '../../assets/illustration2-1024.png'
import { AccessRequestForm } from '../../components/AccessRequestForm'
import { Card } from '../../components/Card'
import { useScrollNavigationPoint } from '../../services/scroll-navigation/scrollNavigation'
import { theme } from '../../services/theme'

export const ContactCard = () => {
  const ref = useScrollNavigationPoint('contact')
  const [state, setState] = useState<{ contactForm: 'hidden' | 'shown' | 'submitted' }>({ contactForm: 'hidden' })

  const handleUseContactFormClick: React.MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault()
    setState((state) => ({ ...state, contactForm: state.contactForm !== 'shown' ? 'shown' : 'hidden' }))
  }

  return (
    <Card ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={4}>
        <Grid xs={12} sm={6} display="flex" alignItems="center">
          <img
            src={illustration}
            alt="Stay informed, stay in touch, and be part of a more vibrant and connected world."
            width="100%"
          />
        </Grid>
        <Grid xs={12} sm={6} display="flex" justifyContent="center" flexDirection="column">
          <Typography variant="body1">
            Let's embark on a journey of digital transformation together and create a more vibrant and connected world.
          </Typography>
          <Typography variant="body1" paddingTop={2}>
            Whether you have questions, inquiries, or just want to learn more about our innovative digital solutions,
            our team is here to assist you.
          </Typography>
          <Typography variant="body1" paddingTop={2}>
            Please{' '}
            <Link href="#" onClick={handleUseContactFormClick}>
              use our contact form
            </Link>{' '}
            or <Link href="mailto:hello@spoty.link">send us an email</Link>.
          </Typography>
          <Collapse in={state.contactForm === 'shown'}>
            <AccessRequestForm onSubmitted={() => setState((state) => ({ ...state, contactForm: 'submitted' }))} />
          </Collapse>
          <Collapse in={state.contactForm === 'submitted'}>
            <Alert severity="success" style={{ marginTop: theme.spacing(2) }}>
              Your request has been successfully submitted. You should receive an email shortly.
            </Alert>
          </Collapse>
        </Grid>
      </Grid>
    </Card>
  )
}
