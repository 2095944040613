import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

import illustration from '../../assets/illustration6-1024.png'
import { Card } from '../../components/Card'
import { useScrollNavigation, useScrollNavigationPoint } from '../../services/scroll-navigation/scrollNavigation'

export const HomeCard: React.FC = () => {
  const { navigate } = useScrollNavigation()
  const ref = useScrollNavigationPoint('home')

  return (
    <Card
      ref={ref}
      style={{
        backgroundImage: `url(${illustration})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50%',

        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={4}>
        <Grid xs={12} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h2" style={{ textAlign: 'center', textShadow: '#fff 0px 0px 4px' }}>
            Connecting Communities
            <br />
            Empowering Organizations
          </Typography>
        </Grid>
      </Grid>
      <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Box style={{ position: 'absolute', bottom: 0, height: 48, marginBottom: 64 }}>
          <IconButton aria-label="delete" size="large" onClick={() => navigate('about')}>
            <ExpandMoreIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>
    </Card>
  )
}
