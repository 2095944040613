import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Emblem from '../assets/emblem-aligned.svg'
import { theme } from '../services/theme'

export type SpotyLabsLogoProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const SpotyLabsLogo: React.FC<SpotyLabsLogoProps> = ({ onClick }) => {
  return (
    <Box style={{ ...styles.box, cursor: onClick ? 'pointer' : 'default' }} onClick={onClick}>
      <img src={Emblem} style={styles.img} alt="Spoty Labs" />
      <Typography variant="h4" component="h1" style={styles.typography}>
        Spoty Labs
      </Typography>
    </Box>
  )
}

const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  img: {
    height: theme.spacing(6),
    paddingRight: theme.spacing(1),
  },
  typography: {
    fontWeight: 700,
    letterSpacing: 0,
    fontFamily: 'Mukta',
    color: '#2a2a2c',
  },
} as const
