import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import AppBar, { AppBarTypeMap } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Unstable_Grid2'

import { scrollNavigation, ScrollPointId, useScrollNavigation } from '../services/scroll-navigation/scrollNavigation'
import { SpotyLabsLogo } from './SpotyLabsLogo'

export const cardTitles = {
  about: 'About',
  solutions: 'Solutions',
  contact: 'Contact',
} as const

export type FloatingMenuProps = {
  position?: AppBarTypeMap['props']['position']
}

export const FloatingMenu: React.FC<FloatingMenuProps> = ({ position }) => {
  const { navigate: scrollNavigate, scrollToTop } = useScrollNavigation()
  const [state, setState] = useState({ isDrawerOpen: false })
  const location = useLocation()
  const domNavigate = useNavigate()

  useEffect(() => {
    scrollToTop()
  }, [location.pathname, scrollToTop])

  const navigate = (scrollPointId: ScrollPointId) => {
    if (location.pathname === '/') {
      scrollNavigate(scrollPointId)
    }

    if (location.pathname !== '/') {
      const hash = scrollNavigation[scrollPointId]
      domNavigate(`/${hash}`)
    }
  }

  const handleDrawerListItemClick = (scrollPointId: ScrollPointId) => {
    setState((state) => ({ ...state, isDrawerOpen: false }))
    setTimeout(() => navigate(scrollPointId), 50)
  }

  const handleMenuListItemClick = (scrollPointId: ScrollPointId) => {
    navigate(scrollPointId)
  }

  return (
    <AppBar position={position} color="transparent" style={styles.appBar}>
      <Container style={styles.container}>
        <SpotyLabsLogo onClick={() => navigate('home')} />
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <IconButton size="large" edge="start" onClick={() => setState((state) => ({ ...state, isDrawerOpen: true }))}>
            <MenuIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid display="flex" flexDirection="row" gap={2}>
            {(['about', 'solutions', 'contact'] as const).map((scrollPointId) => (
              <Button key={scrollPointId} variant="text" onClick={() => handleMenuListItemClick(scrollPointId)}>
                {cardTitles[scrollPointId]}
              </Button>
            ))}
          </Grid>
        </Box>
        <Drawer
          anchor={'right'}
          open={state.isDrawerOpen}
          onClose={() => setState((state) => ({ ...state, isDrawerOpen: false }))}
        >
          <List>
            {(['about', 'solutions', 'contact'] as const).map((scrollPointId) => (
              <ListItem key={scrollPointId} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItemButton onClick={() => handleDrawerListItemClick(scrollPointId)}>
                  <ListItemText primary={cardTitles[scrollPointId]} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Container>
    </AppBar>
  )
}

export const styles = {
  appBar: {
    boxShadow: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 64,
  },
} as const
