import React from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'

import { theme } from '../services/theme'

export type CardProps = React.PropsWithChildren<{
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
}>

export const Card = React.forwardRef<HTMLDivElement, CardProps>(({ style, containerStyle, children }, ref) => {
  return (
    <Grid ref={ref} style={{ ...style, ...styles.box }}>
      <Container style={{ ...containerStyle, ...styles.container }}>{children}</Container>
    </Grid>
  )
})

const styles = {
  box: {
    minHeight: '100vh',
  },
  container: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
} as const
