import { Typography } from '@mui/material'

import { Card } from '../../components/Card'
import { FloatingMenu } from '../../components/FloatingMenu'
import { Footer } from '../../components/Footer'
import { MarkdownFile } from '../../components/MarkdownFile'
import { SpotyLabsMeta } from '../../components/SpotyLabsMeta'
import privacy from './privacy.md'

export const PrivacyScreen: React.FC = () => {
  return (
    <>
      <SpotyLabsMeta title="Privacy Policy" />
      <FloatingMenu position="static" />
      <Card style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h2">Privacy Policy</Typography>
        <MarkdownFile source={privacy} />
      </Card>
      <Footer />
    </>
  )
}
