import { createScrollNavigation } from './createScrollNavigation'

export const scrollNavigation = {
  home: '',
  about: '#about',
  solutions: '#solutions',
  contact: '#contact',
} as const

export type ScrollPointId = keyof typeof scrollNavigation

export const { ScrollNavigationProvider, useScrollNavigation, useScrollNavigationPoint } =
  createScrollNavigation(scrollNavigation)
