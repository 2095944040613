import '@fontsource/mukta/700.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'

import { CssBaseline, ThemeProvider } from '@mui/material'

import { RouterProvider } from 'react-router-dom'
import { router } from './services/navigation/router'
import { theme } from './services/theme'

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}
