import countryList from 'country-list'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'

type AccessRequestFormData = {
  first_name: string
  last_name: string
  organization: string
  email: string
  country: string
}

type AccessRequestFormProps = {
  onSubmitted: () => void
}

export const AccessRequestForm: React.FC<AccessRequestFormProps> = (props) => {
  const countries = countryList.getNames()
  const { handleSubmit, formState, reset, control } = useForm<AccessRequestFormData>({
    defaultValues: { first_name: '', last_name: '', organization: '', email: '', country: '' },
    mode: 'onBlur',
  })

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData()
    formData.append('mauticform[formId]', '1')
    formData.append('mauticform[formName]', 'accessrequestform')
    formData.append('mauticform[messenger]', '1')
    formData.append('mauticform[first_name]', data.first_name)
    formData.append('mauticform[last_name]', data.last_name)
    formData.append('mauticform[organization]', data.organization)
    formData.append('mauticform[email]', data.email)
    formData.append('mauticform[country]', data.country)
    // do not try to JSON.parse the response, it will reload the page
    // possible adjustments: proper cors or mautic api (oauth)
    await fetch('https://communication.spoty.link/form/submit?formId=1', {
      method: 'POST',
      cache: 'no-cache',
      mode: 'no-cors',
      body: formData,
    })
    props.onSubmitted()
    reset()
  })

  return (
    <Grid
      container
      flexDirection="column"
      component="form"
      autoComplete="false"
      onSubmit={onSubmit}
      spacing={2}
      paddingTop={2}
    >
      <Grid display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
        <Controller
          name="first_name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              required
              label="First name"
              variant="outlined"
              error={!!formState.errors['first_name']}
              style={{ flex: 1 }}
              {...field}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              required
              label="Last name"
              variant="outlined"
              error={!!formState.errors['last_name']}
              style={{ flex: 1 }}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid>
        <Controller
          name="organization"
          control={control}
          render={({ field }) => (
            <TextField
              label="Organization"
              variant="outlined"
              fullWidth
              error={!!formState.errors['organization']}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '' },
          }}
          render={({ field }) => (
            <TextField
              required
              label="Email"
              variant="outlined"
              fullWidth
              error={!!formState.errors['email']}
              style={{ flex: 1 }}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid>
        <Controller
          name="country"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              fullWidth
              options={countries}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  required
                  label="Country"
                  variant="outlined"
                  error={!!formState.errors['country']}
                  {...params}
                  {...field}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid display="flex" flexDirection="row" justifyContent="center">
        <Button type="submit" variant="contained" color="primary">
          Send
        </Button>
      </Grid>
    </Grid>
  )
}
