import { Helmet } from 'react-helmet'

export type SpotyLabsMetaProps = {
  title: string
}

export const SpotyLabsMeta: React.FC<SpotyLabsMetaProps> = ({ title }) => {
  const seoTitle = `${title} - Spoty Labs`

  return (
    <Helmet>
      <title>{seoTitle}</title>
      <meta property="og:title" content={seoTitle} />
      <meta name="twitter:title" content={seoTitle} />
    </Helmet>
  )
}
